import { useState, createContext, useContext, useEffect } from "react";
import useGetProperties from "../hooks/useGetProperties";
import { useAuth } from "../../../context/auth";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import useGetPromotion from "../../PropertyCard/components/Promotion/hooks/useGetPromotion";

//create a context
const PropertyContext = createContext();

//context hook
export const useProperty = () => useContext(PropertyContext);

//context provider
export const PropertyContextProvider = ({ children }) => {
  //initial state get all properties

  const [listingType, setListingType] = useState(null);
  const [propertyType, setPropertyType] = useState(null);

  const [orderBy, setOrderBy] = useState("date desc");
  const [orderByButton, setOrderByButton] = useState("date");
  const [homePageNumber, setHomePageNumber] = useState(0);
  const [sort, setSort] = useState("");
  const [page, setPage] = useState(0);
  const [districts, setDistricts] = useState(null);
  const [sellerIds, setSellerIds] = useState([]);
  const [cityId, setCityId] = useState(null);
  const [numberBedrooms, setNumberBedrooms] = useState(null);
  const [numberBathrooms, setNumberBathrooms] = useState(null);
  const [furnishType, setFurnishType] = useState(null);
  const [isOccupiedBefore, setIsOccupiedBefore] = useState(null);
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [minArea, setMinArea] = useState(null);
  const [maxArea, setMaxArea] = useState(null);
  const [mapBounds, setMapBounds] = useState(null);
  const [perPage, setPerPage] = useState(12);
  const [sell, setSell] = useState([]);
  const [mapView, setMapView] = useState("list");
  const [followingData, setFollowingData] = useState(false);
  const [tab, setTab] = useState("all");
  const [propertyData, setPropertyData] = useState(true);
  const [mapFullScreen, setMapFullScreen] = useState(false);
  const [promotion, setPromotion] = useState();
  const router = useRouter();

  const {data: promotionData} = useGetPromotion();
    
    useEffect(() => {
        if (promotionData && promotionData.length>0) {
            setPromotion(promotionData[0]);
        }
    }, [promotionData])
  //change property list on tab change
  const handleChangeListingType = ({ target: { value } }) =>
    setListingType(value);

  //change property type list on tab change
  const handleChangePropertyType = ({ target: { value } }) =>
    setPropertyType(value);

  const handlePageChange = (event, value) => {
    setPage(value - 1);
  };

  // useEffect(() => {
  //   if (router?.query?.view == "map") {
  //     setMapView("map");
  //   }
  // }, [router]);

  const handleClear = () => {
    setListingType(null);
    setCityId();
    setDistricts(null);
    setPropertyType(null);
    setMinPrice(null);
    setMaxPrice(null);
    setMinArea(null);
    setMaxArea(null);
    setNumberBedrooms(null);
    setNumberBathrooms(null);
    setPage(0);
    setSell([]);
  };
  const handleReset = () => {
    setListingType(null);
    setPropertyType(null);
    setHomePageNumber(0);
    setOrderBy("date desc");
    setOrderByButton("date");
    setMapView("list");
  };

  const filterValues = {
    propertyType,
    listingType,
    sort,
    perPage,
    page,
    districts,
    sellerIds,
    cityId,
    numberBedrooms,
    numberBathrooms,
    furnishType,
    isOccupiedBefore,
    minArea,
    minPrice,
    maxArea,
    maxPrice,
    mapBounds,
  };

  // will filtered out values if the value is ''
  // only returns keyword having values, to query the data
  const filteredOutValues = Object.fromEntries(
    Object.entries(filterValues).filter(
      ([_, value]) => value !== "" && value != null
    )
  );

  // const {
  //   data: properties,
  //   isLoading,
  //   isFetching,
  //   refetch: refetchProperties,
  // } = useGetProperties({
  //   filter: filteredOutValues,
  // });

  // const { results, totalResults } = { ...properties };

  return (
    <PropertyContext.Provider
      value={{
        // results,
        // totalResults,
        perPage,
        // isLoading,
        // isFetching,
        listingType,
        propertyType,
        sort,
        filteredOutValues,
        setListingType,
        setSort,
        setPropertyType,
        setOrderBy,
        orderBy,
        handleChangeListingType,
        handleChangePropertyType,
        handlePageChange,
        handleClear,
        districts,
        sellerIds,
        setSellerIds,
        setDistricts,
        cityId,
        setCityId,
        setFurnishType,
        setIsOccupiedBefore,
        numberBathrooms,
        furnishType,
        isOccupiedBefore,
        numberBedrooms,
        setNumberBathrooms,
        setNumberBedrooms,
        // refetchProperties,
        setMinPrice,
        setMaxArea,
        setMaxPrice,
        setMinArea,
        minArea,
        minPrice,
        maxArea,
        maxPrice,
        setMapBounds,
        setPerPage,
        setSell,
        sell,
        setMapView,
        mapView,
        setFollowingData,
        followingData,
        tab,
        setTab,
        propertyData,
        setPropertyData,

        orderByButton,
        setOrderByButton,
        homePageNumber,
        setHomePageNumber,
        handleReset,
        mapFullScreen,
        setMapFullScreen,
        promotion,
        setPromotion
      }}
    >
      {children}
    </PropertyContext.Provider>
  );
};
