import { useRouter } from "next/router";
import { useQuery } from "react-query";
import { useAxios } from '../../../../../hooks/axios';

const useGetPromotion = () => {
  const axios = useAxios();
  const router = useRouter();

  return useQuery(
    ["agent", "promotions"],
    async () => {
        const data = await axios.get(
          `/content/v1/General/promotions`,
          {
            headers: {
              "Accept-Language":
                router?.locale == "en" ? "en-US" : "ar-SA en-US",
            }
          }
        );
        return data?.data?.data;
    },
    { keepPreviousData: true }
  );
};

export default useGetPromotion;
